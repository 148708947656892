import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/home",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeFront.vue"),
  },
  {
    path: "/",
    name: "index",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Index.vue"),
  },

  {
    path: "/Connexion",
    name: "Connexion",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Connexion.vue"),
  },
  {
    path: "/Inscription",
    name: "Inscription",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Inscription.vue"),
  },
  {
    path: "/paiement",
    name: "Paiement",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Paiement.vue"),
  },
  {
    path: "/review/:number",
    name: "Review",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Review.vue"),
  },
  {
    path: "/dossier",
    name: "Dossier",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/Dossier/Dossier.vue"
      ),
  },
  {
    path: "/donneeperso",
    name: "DonneePerso",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/Dossier/DonneePerso.vue"
      ),
  },
  {
    path: "/dossier/paiement",
    name: "Dossier-paiement",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/Dossier/DossierPaiement.vue"
      ),
  },
  {
    path: "/admin/dossier",
    name: "AdminDossier",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/Dossier/AdminDossier.vue"
      ),
  },
  {
    path: "/agence/dossier",
    name: "AgenceDossier",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/agence/Dossier/AgenceDossier.vue"
      ),
  },
  {
    path: "/piece",
    name: "Piece",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/Dossier/Piece.vue"
      ),
  },
  {
    path: "/code",
    name: "Code",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/eleve/Code/Code.vue"),
  },
  {
    path: "/code",
    name: "CodeAccueil",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/Code/CodeAccueil.vue"
      ),
  },
  {
    path: "/examencode",
    name: "ExamenCode",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/ExamenCode/ExamenCode.vue"
      ),
  },
  {
    path: "/editionpermis",
    name: "EditionPermis",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/EditionPermis/EditionPermis.vue"
      ),
  },

  {
    path: "/Agence",
    name: "Agence",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/Agence/Agence.vue"
      ),
  },

  {
    path: "/Lycee",
    name: "Lycee",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/Lycee/Lycee.vue"),
  },

  {
    path: "/Moniteur",
    name: "Moniteur",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/Moniteur/Moniteur.vue"
      ),
  },

  {
    path: "/LieuRDV",
    name: "LieuRDV",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/Lieu/LieuRDV.vue"),
  },

  {
    path: "/LieuEXAM",
    name: "LieuEXAM",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/Lieu/LieuEXAM.vue"
      ),
  },

  {
    path: "/Eleve",
    name: "Eleve",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/Eleve/Eleve.vue"),
  },

  {
    path: "/Vehicule",
    name: "Vehicule",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/Vehicule/Vehicule.vue"
      ),
  },

  {
    path: "/Agence/planning",
    name: "agence-planning",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/agence/Planning.vue"),
  },

  {
    path: "/admin/planning",
    name: "admin-choix-planning",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/choixPlanning.vue"
      ),
  },

  {
    path: "/admin/eleve/liste",
    name: "admin-eleve-list",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/DeleteEleve.vue"
      ),
  },

  {
    path: "/admin/planning/:choix",
    name: "admin-planning",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/secretaire/PlanningAgence.vue"
      ),
  },

  {
    path: "/AgenceEleve",
    name: "AgenceEleve",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/secretaire/Eleve.vue"),
  },

  {
    path: "/AgenceMoniteur",
    name: "AgenceMoniteur",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/secretaire/Moniteur.vue"
      ),
  },

  {
    path: "/Donnees",
    name: "Donnees",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/secretaire/Donnees.vue"),
  },

  {
    path: "/ReservationExam/:EleveId",
    name: "ReservationExam",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/secretaire/ReservationExam.vue"
      ),
  },

  {
    path: "/HistoriqueExam/:EleveId",
    name: "HistoriqueExam",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/secretaire/HistoriqueExam.vue"
      ),
  },

  {
    path: "/Theme",
    name: "Theme",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/secretaire/Theme.vue"),
  },

  {
    path: "/ListeArchive",
    name: "ListeArchive",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/secretaire/ListeArchive.vue"
      ),
  },

  {
    path: "/eleve/conduite",
    name: "eleve-conduite",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/Conduite/Conduite.vue"
      ),
  },
  {
    path: "/eleve/:id/solde/",
    name: "solde",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/Conduite/Solde.vue"
      ),
  },
  {
    path: "/moniteur/planning",
    name: "mono-planning",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/moniteur/PlanningMono.vue"
      ),
  },

  {
    path: "/moniteur/trame/:rdvId/eleve/:id",
    name: "mono-trame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/moniteur/Trame.vue"),
  },
  {
    path: "/agence/trame/eleve/:id",
    name: "agence-trame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/agence/FicheEleve.vue"),
  },

  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotFound.vue"),
  },

  {
    path: "/VueEnsembleTrame",
    name: "VueEnsembleTrame",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/Competence/VueEnsembleTrame.vue"
      ),
  },

  {
    path: "/ListeArchivePartieAdmin",
    name: "ListeArchivePartieAdmin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/Eleve/ListeArchivePartieAdmin.vue"
      ),
  },
  {
    path: "/Calendrier",
    name: "Calendrier",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/Conduite/Calendrier.vue"
      ),
  },
  {
    path: "/ExamenConduite",
    name: "ExamenConduite",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/Conduite/ExamenConduite.vue"
      ),
  },
  {
    path: "/Fonctionnalite",
    name: "Fonctionnalite",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/Fonctionnalite/Fonctionnalite.vue"
      ),
  },
  {
    path: "/CpCm",
    name: "cpcm",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/Moniteur/CpCm.vue"
      ),
  },
  {
    path: "/PlanningAgence",
    name: "PlanningAgence",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/secretaire/PlanningAgence.vue"
      ),
  },
  {
    path: "/PlanningMonoFront",
    name: "PlanningMonoFront",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/moniteur/PlanningMonoFront.vue"
      ),
  },
  {
    path: "/MonoTrame/:rdvId/eleve/:id",
    name: "MonoTrame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/moniteur/MonoTrame.vue"),
  },
  {
    path: "/HomeFront",
    name: "HomeFront",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeFront.vue"),
  },

  {
    path: "/Statistique",
    name: "Statistique",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/Statistique.vue"),
  },

  {
    path: "/JourneePorteOuverte",
    name: "JourneePorteOuverte",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/JourneePorteOuverte.vue"
      ),
  },

  {
    path: "/JourneePorteOuverteEleve",
    name: "JourneePorteOuverteEleve",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/JourneePorteOuverte.vue"
      ),
  },

  {
    path: "/admin/stagecode",
    name: "StageCodeAdmin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/StageCodeAdmin.vue"
      ),
  },

  {
    path: "/StageCode",
    name: "StageCode",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/StageCode.vue"
      ),
  },

  {
    path: "/InProgress",
    name: "InProgress",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InProgress.vue"),
  },

  {
    path: "/TrameFront",
    name: "TrameFront",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/Competence/TrameFront.vue"
      ),
  },
  {
    path: "/ArchiveMonos",
    name: "ArchiveMonos",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/Moniteur/ArchiveMonos.vue"
      ),
  },
  {
    path: "/CodeFront",
    name: "CodeFront",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/eleve/Code/CodeFront.vue"
      ),
  },

  {
    path: "/ChangeMdp/:cleUniq/:uniqId",
    name: "ChangeMdp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChangeMdp.vue"),
  },

  {
    path: "/admin/code",
    name: "adminCode",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/Code/Code.vue"),
  },
  {
    path: "/admin/examcode",
    name: "adminExamCode",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/Code/ExamCode.vue"),
  },
  {
    path: "/ArchiveMonosAgence",
    name: "ArchiveMonosAgence",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/secretaire/ArchiveMonosAgence.vue"
      ),
  },

  {
    path: "/secretaire/code",
    name: "secretaireCode",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/secretaire/PrepaCode.vue"),
  },
  {
    path: "/gestion/examcode",
    name: "examCode",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/secretaire/ExamCode.vue"),
  },
  {
    path: "/secretaire/formationaac",
    name: "formationAac_sec",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/secretaire/FormationAac.vue"),
  },

  {
    path: "/admin/formationaac",
    name: "formationAac_admin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/secretaire/FormationAac.vue"),
  },
  {
    path: "/OtherPaiementDeleted",
    name: "OtherPaiementDeleted",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/agence/OtherPaiementDeleted.vue"),
  },
  {
    path: "/subv",
    name: "SubventionPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/secretaire/Subv.vue"),
  },

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
